html {
  background-color: #000;
}



.page {
  position: relative;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Preview {
  position: relative;
  height:70vh;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#gradient1-canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  --gradient-color-1: #DB2BFF; 
  --gradient-color-2: #1335FA; 
  --gradient-color-3: #FF40CA;  
  --gradient-color-4: #76EAFA;
  z-index:0;
}
#gradient2-canvas {
  height: 100%;
  width: 100%;
  display: block;
  --gradient-color-1: #1C3FD4; 
  --gradient-color-2: #1C3FD4; 
  --gradient-color-3: #8DA9E9;  
  --gradient-color-4: #F1CD7A;
  z-index:0;
}
#gradient3-canvas {
  height: 100%;
  width: 100%;
  display: block;
  --gradient-color-1: #000; 
  --gradient-color-2: #E4993D; 
  --gradient-color-3: #BE2A24;  
  --gradient-color-4: #BE2A24;
  z-index:0;
}
#gradient4-canvas {
  height: 100%;
  width: 100%;
  display: block;
  --gradient-color-1: #000000;   --gradient-color-2: #373737; 
  --gradient-color-3: #242424;  
  --gradient-color-4: #000000;
  z-index:0;
}

.logo {
  display: flex;
  align-items: center;

}

.moon {
  height: calc(20px + 8*(100vw - 320px)/880);
  position: fixed;
  right: 0;
  top: 0;
  margin: 24px;
  z-index: 10;
  mix-blend-mode: difference;
}

.Header {
  background-color: black;
  height: 70vh;
  padding: 0 5.2vw;
  display: flex;
  align-items: center;
}

.HeaderTitleMain {
  margin: 0;
  font-family: 'Poppins', "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  color: #fff;
  z-index:100;
  font-size: calc(30px + 8*(100vw - 320px)/880);
  /* margin-left: 16px; */
}

.HeaderTitle {
  margin: 0;
  font-family: 'Poppins', "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  color: #fff;
  z-index:100;
  font-size: calc(30px + 8*(100vw - 320px)/880);
}

.HeaderDescriptionMain {
  font-family: 'Poppins', "Helvetica Neue", Arial, sans-serif;
  font-size: calc(16px + 5*(100vw - 320px)/880);
  font-weight: 500; 
  color: #fff;
  margin: 0;
  margin-top: 1vmin;
}

.HeaderDescription {
  font-family: 'Poppins', "Helvetica Neue", Arial, sans-serif;
  font-size: calc(16px + 5*(100vw - 320px)/880);
  font-weight: 500; 
  color: #fff;
  margin: 0;
  margin-top: 3vmin;
}

.Section1 {
  background-color: #2e0750;
  /* height: 50vh; */
  padding: 10vh 5.2vw;
  display: flex;
  align-items: center;
}

.Section2 {
  background-color: #0f1c57;
  /* height: 50vh; */
  padding: 10vh 5.2vw;
  display: flex;
  align-items: center;
}

.Section3 {
  background-color: #4a0915;
  /* height: 50vh; */
  padding: 10vh 5.2vw;
  display: flex;
  align-items: center;
}

.Image {
  box-sizing: border-box;
  max-height: 85%;
  max-width: 85%;
  position: absolute;
  background-color: rgba(255,255,255,0.3);
  backdrop-filter: blur(40px); 
  /* border: solid 1px rgba(255,255,255,0.15); */
  box-shadow:inset 0px 0px 0px 1px rgba(255,255,255,0.1);
  border-radius: 5px;
  z-index: 1000;
}

.signup {
  position: relative;
  height: 20vmax;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkContainer {
  position: absolute;
}


.link {
  
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  padding: 12px;
  color: #fff;
  text-decoration: none;
  font-family: 'Poppins', "Helvetica Neue", Arial, sans-serif;
  font-weight: 700; 
  font-size: calc(30px + 8*(100vw - 320px)/880);
  border-bottom: solid 7px white;
}

.HeaderLinkMain {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  text-decoration: none;
  font-family: 'Poppins', "Helvetica Neue", Arial, sans-serif;
}


.HeaderGetAccess {
  margin-top: 3vmin;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.arrow {
  margin-top: 1.2vmin;
  margin-left: 1.3vmin;
  height:2vmin; 
  transition: transform 0.2s ease-in-out;
}

.HeaderGetAccess:hover img {
  transform: translateX(5px)
}